import Container from '@components/Container';
import Layout from '@components/Layout';
import LayoutTopSection from '@components/LayoutTopSection';
import Paragraph from '@components/Typography/Paragraph';
import CommonPhoto from '@images/workers/michal-and-przemek.webp';
import styled from 'styled-components';
import React from 'react';

const ImgBox = styled.div`
  display: flex;
  justify-content: flex-end;
  background: var(--footer-background);
  box-shadow: 0 0 10px black;
  position: relative;
  margin: 0 0 2rem 0;
`;

const ImgCard = styled.div`
  height: 100%;
  width: 100%;
  background: var(--main-gradient);
  box-shadow: 0 0 10px black;
  position: absolute;
  z-index: -1;
  top: 1rem;
  left: 1rem;
`;

const About = () => {
  const aboutUs = 'O nas';
  const renderContent = () => (
    <Paragraph>
      Nasz zespół to ludzie z wieloletnim doświadczeniem w zarządzaniu, sprzedaży i współpracy z
      Klientem indywidualnym oraz biznesowym. Umiejętności i wiedzę potrzebną do świadczenia usług
      na najwyższym poziomie, zdobywaliśmy w czasie pracy w sektorze finansów i handlu. Jesteśmy
      firmą działającą w obszarze pośrednictwa kredytowego i&nbsp;doradztwa biznesowego. Pomagamy
      naszym Klientom w uzyskaniu kredytów hipotecznych, gotówkowych i firmowych. Prowadzimy obsługę
      księgowo - kadrową dla firm oraz wspieramy przedsiębiorców w zakresie prowadzenia projektów
      inwestycyjnych. Sprawdź w czym możemy Ci pomóc odwiedzając zakładkę usługi lub przez
      bezpośredni kontakt.
    </Paragraph>
  );

  const renderSiteImg = () => (
    <ImgBox>
      <img src={CommonPhoto} alt="Michal and Przemek" width="100%" />
      <ImgCard />
    </ImgBox>
  );

  return (
    <Layout pageTitle={aboutUs}>
      <Container>
        <LayoutTopSection title={aboutUs} content={renderContent()} rightSide={renderSiteImg()} />
      </Container>
    </Layout>
  );
};

export default About;
